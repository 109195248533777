import { db } from 'cf-core/src/config/firebase'
import { moment } from 'cf-utils'
import { get, isEqual, isEmpty } from 'lodash'
import * as utils from 'cf-utils'

const DEFAULT_STATE = {
  // Restaurant data
  loading: true,
  restaurantId: null,
  Locations: {},
  commissionFee: 0,
  name: null,

  // Restaurant Location data
  restaurantLocationLoading: true,
  locationName: null,
  address: null,
  deliveryZone: [],
  deliveryFee: 0,
  phoneNumber: null,
  email: null,
  deposit: 0,
  Categories: {},
  Products: {},
  categoryOrder: [],
  activeCategoryOrder: [],
  activeProducts: {},
  hours: {},
  deliveryHours: {},
  menus: {},
  deliveryEnabled: false,
  printingEnabled: false,
  orderOpen: null,
  deliveryOpen: null,
  rewards: {},
  deliveryTime: 0,
  waitTime: 0,
  minOrder: 0,

  // Redux only data
  locationId: null,
}

export default (_restaurantId, _defaultLocationId) => ({
  state: {
    ...DEFAULT_STATE,
    restaurantId: _restaurantId,
    locationId: _defaultLocationId,
  },
  reducers: {
    resetRestaurant: () => DEFAULT_STATE,
    resetRestaurantLocation: ({ restaurantId, Locations, loading, commissionFee, name }) => ({
      ...DEFAULT_STATE,
      restaurantId,
      Locations,
      loading,
      commissionFee,
      name,
    }),
    setRestaurantId: (state, restaurantId) => {
      return { ...state, restaurantId }
    },
    setLocationId: (state, locationId) => {
      return { ...state, locationId }
    },
    setRestaurant: (state, restaurant) => ({
      ...state,
      ...restaurant,
    }),
    setCategories: (state, Categories) => ({
      ...state,
      Categories,
    }),
    setProducts: (state, Products) => ({
      ...state,
      Products,
    }),
    setLocations: (state, Locations) => ({
      ...state,
      Locations,
    }),
    setLoading: (state, loading) => ({
      ...state,
      loading,
    }),
    setRestaurantLocationLoading: (state, restaurantLocationLoading) => ({
      ...state,
      restaurantLocationLoading,
    }),
    setActiveCategoryOrder: (state, activeCategoryOrder) => ({
      ...state,
      activeCategoryOrder,
    }),
    setActiveProducts: (state, activeProducts) => ({
      ...state,
      activeProducts,
    }),
  },
  actions: ({ dispatch, getState }) => ({
    getRestaurantId() {
      return getState().restaurant.restaurantId
    },
    getSelectedLocationId() {
      return getState().restaurant.locationId
    },
    getSelectedLocation() {
      const locations = dispatch.restaurant.getLocations()
      const selectedLocationId = dispatch.restaurant.getSelectedLocationId()
      if (locations && selectedLocationId && locations[selectedLocationId]) {
        return locations[selectedLocationId]
      }
    },
    getName() {
      return getState().restaurant.name
    },
    getLocationName() {
      return getState().restaurant.locationName
    },
    getAddress() {
      return getState().restaurant.address
    },
    getPhoneNumber() {
      return getState().restaurant.phoneNumber
    },
    getFormattedPhoneNumber() {
      return utils.formatPhoneNumber(dispatch.restaurant.getPhoneNumber())
    },
    getEmail() {
      return getState().restaurant.email
    },
    getHours() {
      return getState().restaurant.hours
    },
    getDeliveryHours() {
      return getState().restaurant.deliveryHours
    },
    getDeliveryHoursTime() {
      const hours = dispatch.restaurant.getDeliveryHours()
      const day = moment().day()
      return isEmpty(hours)
        ? 'Not Available'
        : hours[day] && hours[day].open && hours[day].close
        ? `${moment(hours[day].open).format('LT')} - ${moment(hours[day].close).format('LT')}`
        : 'Not Available'
    },
    getMenus() {
      return getState().restaurant.menus
    },
    getMenu(menuId) {
      return dispatch.restaurant.getMenus()[menuId] || {}
    },
    getMenuHours(menuId) {
      const { hours } = dispatch.restaurant.getMenu(menuId)
      const day = moment().day()
      return isEmpty(hours)
        ? 'Not Available'
        : hours[day] && hours[day].open && hours[day].close
        ? `${moment(hours[day].open).format('LT')} - ${moment(hours[day].close).format('LT')}`
        : 'Not Available'
    },
    getIsMenuActive(menuId) {
      return dispatch.restaurant.getMenu(menuId).active
    },
    getActiveMenuCount() {
      return Object.values(dispatch.restaurant.getMenus()).reduce((acc, menu) => {
        if (menu.active) {
          return (acc = acc + 1)
        }
        return acc
      }, 0)
    },
    getDeliveryEnabled() {
      return !!getState().restaurant.deliveryEnabled
    },
    getPrintingEnabled() {
      return !!getState().restaurant.printingEnabled
    },
    getDeliveryFee() {
      return getState().restaurant.deliveryFee
    },
    getDeliveryZone() {
      return getState().restaurant.deliveryZone
    },
    getOrderOpen() {
      return !!getState().restaurant.orderOpen
    },
    getDeliveryOpen() {
      return !!getState().restaurant.deliveryOpen
    },
    getMinOrder() {
      return getState().restaurant.minOrder
    },
    getIsStoreOpen() {
      // Store is open iff (not loading, orderOpen, now is during store hours)

      const orderOpen = getState().restaurant.orderOpen
      // return null if we don't know if order is open (ie. still loading)
      if (orderOpen === null) {
        return null
      }
      if (orderOpen === false) {
        return false
      }

      // To properly check if currentTime is between store hours,
      // Check both today's weekday and yesterday's weekday to see if currentTime is between open and close.

      const todayOpen = utils.getIsOpenForGivenTime({
        hours: dispatch.restaurant.getHours(),
        targetDate: moment(),
        time: moment(),
        waitTime: dispatch.restaurant.getWaitTime(),
      })
      if (todayOpen) {
        return true
      }

      const yesterdayOpen = utils.getIsOpenForGivenTime({
        hours: dispatch.restaurant.getHours(),
        targetDate: moment().subtract(1, 'days'),
        time: moment(),
        waitTime: dispatch.restaurant.getWaitTime(),
      })
      return yesterdayOpen
    },
    getIsDeliveryHoursOpen() {
      // Store is open iff (not loading, orderOpen, now is during store hours)

      const deliveryOpen = getState().restaurant.deliveryOpen
      // return null if we don't know if order is open (ie. still loading)
      if (deliveryOpen === null) {
        return null
      }
      if (deliveryOpen === false) {
        return false
      }

      // To properly check if currentTime is between store hours,
      // Check both today's weekday and yesterday's weekday to see if currentTime is between open and close.

      const todayOpen = utils.getIsOpenForGivenTime({
        hours: dispatch.restaurant.getDeliveryHours(),
        targetDate: moment(),
        time: moment(),
        waitTime: 0,
      })
      if (todayOpen) {
        return true
      }

      const yesterdayOpen = utils.getIsOpenForGivenTime({
        hours: dispatch.restaurant.getDeliveryHours(),
        targetDate: moment().subtract(1, 'days'),
        time: moment(),
        waitTime: 0,
      })
      return yesterdayOpen
    },
    getRewards() {
      return getState().restaurant.rewards
    },
    getRewardsCount() {
      return Object.keys(dispatch.restaurant.getRewards()).length
    },
    getCommissionFee() {
      return getState().restaurant.commissionFee || 0
    },
    getDeliveryTime() {
      return getState().restaurant.deliveryTime || 0
    },
    getWaitTime() {
      return getState().restaurant.waitTime || 0
    },
    getCategoryOrder(menuId) {
      if (menuId) {
        return dispatch.restaurant.getMenu(menuId).categoryOrder || []
      }
      return getState().restaurant.categoryOrder
    },
    updateActiveCategoryOrder() {
      const menus = dispatch.restaurant.getMenus()
      const categoryOrder = Object.values(menus).reduce((prev, menu) => {
        const { active, hours } = menu
        if (active) {
          const isOpen = utils.getIsOpenForGivenTime({
            hours,
            targetDate: moment(),
            time: moment(),
            waitTime: 0,
          })

          if (isOpen) {
            const categoryOrder = get(menu, 'categoryOrder')
            prev = prev.concat(categoryOrder)
          } else {
            const isYesterdayOpen = utils.getIsOpenForGivenTime({
              hours,
              targetDate: moment().subtract(1, 'days'),
              time: moment(),
              waitTime: 0,
            })
            if (isYesterdayOpen) {
              const categoryOrder = get(menu, 'categoryOrder')
              prev = prev.concat(categoryOrder)
            }
          }
        }
        return prev
      }, [])
      const prevActiveCategoryOrder = dispatch.restaurant.getActiveCategoryOrder()
      if (categoryOrder.length === 0 && menus.default) {
        if (!isEqual(prevActiveCategoryOrder, menus.default.categoryOrder)) {
          dispatch.restaurant.setActiveCategoryOrder(menus.default.categoryOrder)
          dispatch.restaurant.updateActiveProducts()
        }
      } else if (!isEqual(prevActiveCategoryOrder, categoryOrder)) {
        dispatch.restaurant.setActiveCategoryOrder(categoryOrder)
        dispatch.restaurant.updateActiveProducts()
      }
    },
    getActiveCategoryOrder() {
      return getState().restaurant.activeCategoryOrder || []
    },
    updateActiveProducts() {
      const activeProducts = {}
      const activeCategoryOrder = dispatch.restaurant.getActiveCategoryOrder()

      for (const categoryId of activeCategoryOrder) {
        const productOrder = dispatch.restaurant.getProductOrder(categoryId)
        for (const productId of productOrder) {
          // Check if product exists
          const productDetails = dispatch.restaurant.getProductDetails(productId)
          if (!productDetails) {
            continue
          }
          // Check if product is active
          if (!dispatch.restaurant.getIsProductActive(productId)) {
            continue
          }

          // Add product options
          if (productDetails.options && productDetails.options.length > 0) {
            for (const productOptionId of productDetails.options) {
              const productOptionDetails = dispatch.restaurant.getProductDetails(productId)
              if (productOptionDetails) {
                activeProducts[productOptionId] = productOptionDetails
              }
            }
          }
          activeProducts[productId] = productDetails
        }
      }
      dispatch.restaurant.setActiveProducts(activeProducts)
    },
    getActiveProducts() {
      return getState().restaurant.activeProducts
    },
    getProductDetails(productId) {
      return getState().restaurant.Products[productId]
    },
    getProductOptions(productId) {
      if (!productId) return null
      return get(dispatch.restaurant.getProductDetails(productId), 'options', null)
    },
    getProductOptionsDetails(productId) {
      // get array of optionIds
      const options = dispatch.restaurant.getProductOptions(productId)
      if (options) {
        return options.map(optionId => dispatch.restaurant.getProductDetails(optionId))
      }
      return null
    },
    getProducts() {
      return getState().restaurant.Products
    },
    getCategories() {
      return getState().restaurant.Categories
    },
    getCategory(categoryId) {
      return dispatch.restaurant.getCategories()[categoryId]
    },
    getCategoryImageUrl(categoryId) {
      const category = dispatch.restaurant.getCategory(categoryId)
      return get(category, 'imageUrl')
    },
    getProductOrder(categoryId) {
      const category = dispatch.restaurant.getCategory(categoryId)
      return get(category, 'productOrder', [])
    },
    getOptions(productId) {
      return dispatch.restaurant.getProductDetails(productId).options || []
    },
    getLocations() {
      return getState().restaurant.Locations
    },
    getHasSingleLocation() {
      return Object.keys(dispatch.restaurant.getLocations()).length === 1
    },
    getHasMultipleLocations() {
      return Object.keys(dispatch.restaurant.getLocations()).length > 1
    },
    getLoading() {
      return getState().restaurant.loading || getState().restaurant.restaurantLocationLoading
    },
    getLoadingLocations() {
      return isEmpty(getState().restaurant.Locations)
    },
    getIsProductActive(productId) {
      const productDetails = dispatch.restaurant.getProductDetails(productId)
      return utils.getIsProductActive(productDetails)
    },
    getIsMenuProductActive(productId) {
      return Object.keys(dispatch.restaurant.getActiveProducts()).includes(productId)
    },
    getRestaurantDoc() {
      return db.collection('Restaurants').doc(dispatch.restaurant.getRestaurantId())
    },
    getSelectedLocationDoc() {
      return dispatch.restaurant.getLocationsDoc().doc(dispatch.restaurant.getSelectedLocationId())
    },
    getCategoriesDoc() {
      return dispatch.restaurant.getSelectedLocationDoc().collection('Categories')
    },
    getProductsDoc() {
      return dispatch.restaurant.getSelectedLocationDoc().collection('Products')
    },
    getLocationsDoc() {
      return dispatch.restaurant.getRestaurantDoc().collection('Locations')
    },
    getOrdersDoc() {
      return dispatch.restaurant.getRestaurantDoc().collection('Orders')
    },
    subscribeRestaurant() {
      const collectionLoadedStatus = {
        Restaurant: false,
        Locations: false,
      }
      let collectionsToLoadCount = Object.keys(collectionLoadedStatus).length
      const setCollectionToLoaded = collectionName => {
        if (collectionLoadedStatus[collectionName] === false) {
          collectionLoadedStatus[collectionName] = true
          collectionsToLoadCount--
          if (collectionsToLoadCount === 0) {
            dispatch.restaurant.setLoading(false)
          }
        }
      }
      const unsubsRestaurant = dispatch.restaurant.getRestaurantDoc().onSnapshot(
        snapshot => {
          const restaurantData = snapshot.data()
          setCollectionToLoaded('Restaurant')
          dispatch.restaurant.setRestaurant(restaurantData)
        },
        () => setCollectionToLoaded('Restaurant')
      )
      const unsubsLocations = dispatch.restaurant.getLocationsDoc().onSnapshot(
        snapshot => {
          const locations = {}
          snapshot.forEach(doc => {
            locations[doc.id] = doc.data()
            locations[doc.id].id = doc.id
          })

          // Set locationId if there is only one location
          const locationIds = Object.keys(locations)
          if (locationIds.length === 1) {
            dispatch.restaurant.setLocationId(locationIds[0])
          } else {
            const selectedLocationId = dispatch.restaurant.getSelectedLocationId()
            // Check if selectedLocationId is valid (that was set by user model)
            if (!locations[selectedLocationId]) {
              dispatch.restaurant.setLocationId(null)
            }
          }

          dispatch.restaurant.setLocations(locations)
          setCollectionToLoaded('Locations')
        },
        () => setCollectionToLoaded('Locations')
      )
      const unsubscribeAndResetRestaurant = () => {
        unsubsRestaurant()
        unsubsLocations()
        dispatch.restaurant.resetRestaurant()
      }
      return unsubscribeAndResetRestaurant
    },
    subscribeRestaurantLocation() {
      dispatch.restaurant.setRestaurantLocationLoading(true)
      const collectionLoadedStatus = {
        Categories: false,
        Products: false,
        RestaurantLocation: false,
      }
      let collectionsToLoadCount = Object.keys(collectionLoadedStatus).length
      const setCollectionToLoaded = collectionName => {
        if (collectionLoadedStatus[collectionName] === false) {
          collectionLoadedStatus[collectionName] = true
          collectionsToLoadCount--
          if (collectionsToLoadCount === 0) {
            dispatch.restaurant.updateActiveCategoryOrder()
            dispatch.restaurant.updateActiveProducts()
            dispatch.restaurant.setRestaurantLocationLoading(false)
          }
        }
      }
      const unsubsRestaurantLocation = dispatch.restaurant.getSelectedLocationDoc().onSnapshot(
        snapshot => {
          const selectedLocationData = snapshot.data()
          setCollectionToLoaded('RestaurantLocation')
          dispatch.restaurant.setRestaurant(selectedLocationData)
          dispatch.restaurant.updateActiveCategoryOrder()
          dispatch.restaurant.updateActiveProducts()
        },
        () => setCollectionToLoaded('RestaurantLocation')
      )
      const unsubsCategories = dispatch.restaurant.getCategoriesDoc().onSnapshot(
        snapshot => {
          const categories = {}
          snapshot.forEach(doc => {
            categories[doc.id] = doc.data()
            categories[doc.id].id = doc.id
          })
          dispatch.restaurant.setCategories(categories)
          dispatch.restaurant.updateActiveCategoryOrder()
          dispatch.restaurant.updateActiveProducts()
          setCollectionToLoaded('Categories')
        },
        () => setCollectionToLoaded('Categories')
      )
      const unsubsProducts = dispatch.restaurant.getProductsDoc().onSnapshot(
        snapshot => {
          const products = {}
          snapshot.forEach(doc => {
            products[doc.id] = doc.data()
            products[doc.id].id = doc.id
          })
          dispatch.restaurant.setProducts(products)
          dispatch.restaurant.updateActiveCategoryOrder()
          dispatch.restaurant.updateActiveProducts()
          setCollectionToLoaded('Products')
        },
        () => setCollectionToLoaded('Products')
      )
      const unsubscribeRestaurantLocation = () => {
        unsubsCategories()
        unsubsProducts()
        unsubsRestaurantLocation()
      }
      return unsubscribeRestaurantLocation
    },
  }),
})
