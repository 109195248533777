import React, { Component } from 'react'
import { css } from 'emotion'
import { promotion1, promotion2, promotion3 } from 'images'
import { MEDIA_QUERY } from 'styles'

class Promotions extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.promoContainer}>
            <img className={styles.image} src={promotion1} alt="Promo 1" />
          </div>
          <div className={styles.promoContainer}>
            <img className={styles.image} src={promotion2} alt="Promo 2" />
          </div>
          <div className={styles.promoContainer}>
            <img className={styles.image} src={promotion3} alt="Promo 3" />
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: css({
    paddingTop: '4rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    [MEDIA_QUERY.MD]: {
      overflowX: 'auto',
    },
  }),
  row: css({
    maxWidth: '130rem',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '3%',
    width: '100%',
    [MEDIA_QUERY.MD]: {
      padding: '0 1rem 0 1rem',
      overflowX: 'auto',
    },
  }),
  promoContainer: css({
    width: '33%',
    [MEDIA_QUERY.MD]: {
      minWidth: '85vw',
    },
  }),
  header: css({
    width: '80%',
    objectFit: 'contain',
  }),
  image: css({
    width: '90%',
  }),
}

export default Promotions
