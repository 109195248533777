import React from 'react'
import { CFView, Text } from 'components'
import { css } from 'emotion'

const OrderButtonView = ({ disabled, label }) => (
  <CFView
    center
    className={styles.button}
    style={disabled ? { opacity: 0.5 } : {}}
  >
    <Text content h5 white bold>
      {label}
    </Text>
  </CFView>
)

const styles = {
  button: css({
    paddingLeft: '3rem',
    paddingRight: '3rem',
    height: '3.5rem',
    background: '#D0021B',
    border: 0,
    borderRadius: '0.4rem',
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  }),
}

export default OrderButtonView
