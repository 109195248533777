import React from 'react'
import { orderButton } from 'images'
import { css } from 'emotion'
import { MEDIA_QUERY } from 'styles'

export default () => (
  <img src={orderButton} alt="Order Now" className={styles.orderButton} />
)

const styles = {
  orderButton: css({
    [MEDIA_QUERY.SM]: {
      width: '30rem',
    },
  }),
}
