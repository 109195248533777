import React, { PureComponent } from 'react'
import { css } from 'emotion'
import { PageHeader } from 'components'
// import { woodbg } from 'images'
import { MEDIA_QUERY } from 'styles'

class Container extends PureComponent {
  render() {
    const { children, className } = this.props
    const classNames = css(className, styles.container)
    return (
      <section className={classNames}>
        <PageHeader />
        {children}
      </section>
    )
  }
}

const styles = {
  container: css({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem 2rem 0 2rem',
    backgroundSize: 'cover',
    [MEDIA_QUERY.MD]: {
      padding: 0,
    },
  }),
}

export default Container
