import React, { Component } from 'react'
import { CFModal, OrderStatus, PrimaryButton } from 'cf-web-app/components'
import { isEmpty } from 'lodash'
import { ThemeContext } from 'cf-web-app'

export default class OrderDetailsModal extends Component {
  render() {
    const { isOpen, onRequestClose } = this.props
    return (
      <CFModal isOpen={isOpen} onRequestClose={onRequestClose}>
        <ThemeContext.Consumer>
          {({ User }) => (
            <User.OrderDetailsModalView
              OrderDetailsElement={this._renderOrderDetails()}
              CloseButtonElement={
                <PrimaryButton label="CLOSE" onClick={onRequestClose} />
              }
            />
          )}
        </ThemeContext.Consumer>
      </CFModal>
    )
  }
  _renderOrderDetails = () => {
    const {
      orderType = '',
      orderNumber,
      discount = 0,
      products,
      id,
      subTotal,
      deliveryFee = 0,
      tipAmount = 0,
      taxAmount,
      rewards = {},
      addedCharges,
      total,
    } = this.props.order
    return (
      <ThemeContext.Consumer>
        {({ User }) => (
          <User.OrderDetailsView
            orderNumber={orderNumber}
            OrderStatusElement={<OrderStatus orderId={id} />}
            ProductListElement={Object.values(products).map(product => {
              const { count, name, price } = product
              return (
                <User.ProductRowView
                  key={name}
                  count={count}
                  name={name}
                  priceFormatted={(count * price).toFixed(2)}
                />
              )
            })}
            RewardListElement={
              !isEmpty(rewards) &&
              Object.values(rewards).map(reward => {
                const { name, count, productId } = reward
                if (productId) {
                  return (
                    <User.RewardRowView
                      key={name}
                      count={count}
                      name={name}
                      priceFormatted={'FREE'}
                    />
                  )
                }
                return null
              })
            }
            DiscountElement={
              !isEmpty(rewards) &&
              Object.values(rewards).map(reward => {
                const { name, count, totalDiscountSubtotal } = reward
                if (totalDiscountSubtotal) {
                  return (
                    <User.DiscountRowView
                      key={name}
                      count={count}
                      name={name}
                      priceFormatted={totalDiscountSubtotal.toFixed(2)}
                    />
                  )
                }
                return null
              })
            }
            AddedChargesElement={
              !!addedCharges &&
              addedCharges.map(charge => (
                <User.AddedChargeRowView
                  key={charge.name}
                  name={charge.name}
                  priceFormatted={charge.price.toFixed(2)}
                />
              ))
            }
            orderType={orderType}
            subTotalFormatted={(subTotal - discount).toFixed(2)}
            deliveryFeeFormatted={deliveryFee.toFixed(2)}
            tipFormatted={tipAmount.toFixed(2)}
            taxFormatted={taxAmount.toFixed(2)}
            totalFormatted={total.toFixed(2)}
          />
        )}
      </ThemeContext.Consumer>
    )
  }
}
