import React, { Component } from 'react'
import { css } from 'emotion'
import { restaurant } from 'images'
import { MEDIA_QUERY } from 'styles'

export default class Restaurant extends Component {
  render() {
    return (
      <img src={restaurant} alt="Restaurant" className={styles.restaurant} />
    )
  }
}

const styles = {
  restaurant: css({
    width: '100%',
    maxWidth: '130rem',
    objectFit: 'contain',
    marginTop: '-8rem',
    [MEDIA_QUERY.MD]: {
      marginTop: 0,
    },
  }),
}
