import React, { Component } from 'react'
import { css } from 'emotion'
import { CFLink, CFView, Space, Text } from 'components'
import { appStore, googlePlay, locationMap } from 'images'
import { MEDIA_QUERY } from 'styles'

class Contact extends Component {
  render() {
    const { HoursElement, ContactUsElement } = this.props
    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.column}>
            <Text heading h2 bold color="#FFDC00">
              CONTACT US
            </Text>
            <Space h2 />
            {ContactUsElement}
            <Space h2 />
            <Text heading h2 bold color="#FFDC00">
              DOWNLOAD APP
            </Text>
            <Space h2 />
            <CFView row>
              <div className={styles.buttonBar}>
                <CFLink href="https://apps.apple.com/us/app/on-on-chinese-restaurant/id1469176046?ls=1">
                  <img
                    className={styles.mobile}
                    src={appStore}
                    alt="App Store"
                  />
                </CFLink>
                <Space w2 />
                <CFLink href="https://play.google.com/store/apps/details?id=tech.codefusion.onon">
                  <img
                    className={styles.mobile}
                    src={googlePlay}
                    alt="Goodle Play"
                  />
                </CFLink>
              </div>
            </CFView>
          </div>
          <div className={styles.column}>
            <Text heading h2 bold color="#FFDC00">
              BUSINESS HOURS
            </Text>
            <Space h2 />
            <div className={styles.hours}>{HoursElement}</div>
          </div>
          <CFLink href="https://goo.gl/maps/gMxavGX3zaQJhnALA">
            <img
              src={locationMap}
              className={styles.locationMap}
              alt="location"
            />
          </CFLink>
        </div>
      </div>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '4rem',
    backgroundColor: '#1D1D1D',
  }),
  innerContainer: css({
    width: '100%',
    maxWidth: '130rem',
    display: 'flex',
    paddingLeft: '4rem',
    justifyContent: 'space-between',
    [MEDIA_QUERY.MD]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: 0,
      textAlign: 'center',
    },
  }),
  locationMap: css({
    cursor: 'pointer',
    [MEDIA_QUERY.MD]: {
      marginTop: '2rem',
      width: '100%',
    },
  }),
  hours: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '35rem',
  }),
  mobile: css({
    height: '4rem',
    objectFit: 'contain',
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  }),
  column: css({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '4rem',
    [MEDIA_QUERY.MD]: {
      alignItems: 'center',
      marginTop: '2rem',
    },
  }),
  buttonBar: css({
    display: 'flex',
  }),
  icon: css({
    height: '4rem',
    width: '4rem',
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  }),
}

export default Contact
