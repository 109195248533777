export const COLOR = {
  primary: '#1E1E1E',
  secondary: 'blue',
  white: '#fff',
  black: '#1E1E1E',
  disabled: 'grey',
  lightGrey: '#E6E6E6',
  grey: '#BBBBBB',
  mediumGrey: '#888888',
  darkGrey: '#4A4A4A',
  red: '#D0021B',
  Orange: '#FF9F00',
  Gold: '#936E4E',
  Green: '#BEE100',
  darkGreen: '#0FD05A',
}

export const SCREEN = {
  XS: 375,
  SM: 576,
  MD: 767,
  LG: 992,
  XL: 1200,
}

export const MEDIA_QUERY = {
  XS: `@media (max-width: ${SCREEN.XS}px)`,
  SM: `@media (max-width: ${SCREEN.SM}px)`,
  MD: `@media (max-width: ${SCREEN.MD}px)`,
  LG: `@media (max-width: ${SCREEN.LG}px)`,
  XL: `@media (max-width: ${SCREEN.XL}px)`,
}
