import React from 'react'
import { css } from 'emotion'
import PageFooter from './PageFooter'
import Hero from './Hero'
import Promotions from './Promotions'
import Restaurant from './Restaurant'
import About from './About'
import Contact from './Contact'
import { MEDIA_QUERY } from 'styles'

class HomeView extends React.Component {
  render() {
    const {
      HoursElement,
      ContactUsElement,
      OrderNowButtonElement,
      LogoElement,
    } = this.props
    return (
      <section className={styles.container}>
        <Hero OrderNowButtonElement={OrderNowButtonElement} />
        <Promotions />
        <About />
        <Restaurant />
        <Contact
          HoursElement={HoursElement}
          LogoElement={LogoElement}
          ContactUsElement={ContactUsElement}
        />
        <PageFooter />
      </section>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem',
    [MEDIA_QUERY.SM]: {
      padding: 0,
    },
  }),
  innerContainer: css({
    width: '100%',
    maxWidth: '120rem',
  }),
}

export default HomeView
