import React from 'react'
import { css } from 'emotion'
import * as images from 'images'

const RewardsRightArrowButtonView = ({ disabled }) => (
  <img
    className={styles.rightArrow}
    src={images.rewardArrowRight}
    style={disabled ? { opacity: 0.5 } : {}}
    alt="Right Arrow"
  />
)

const styles = {
  rightArrow: css({
    position: 'absolute',
    top: '42.5%',
    right: '1.5rem',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
    '&:active': {
      transform: 'translateY(-1px)',
    },
  }),
}

export default RewardsRightArrowButtonView
