import React, { PureComponent, Fragment } from 'react'
import { Provider } from 'react-redux'
import Routes from './routes'
import { store, dispatch } from 'cf-web-app/store'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCheckCircle,
  faChevronRight,
  faEdit,
  faMapMarker,
  faMinusCircle,
  faPlusCircle,
  faShoppingCart,
  faSignInAlt,
  faSpinner,
  faTimesCircle,
  faUser,
  faUtensils,
  faStar,
} from '@fortawesome/free-solid-svg-icons'
import {
  CFSelect,
  Notification,
  ReviewModal,
  SubscribeRestaurantLocation,
  CFRenderless,
} from 'cf-web-app/components'

library.add(
  faCheckCircle,
  faChevronRight,
  faEdit,
  faMapMarker,
  faMinusCircle,
  faPlusCircle,
  faShoppingCart,
  faSignInAlt,
  faSpinner,
  faTimesCircle,
  faUser,
  faUtensils,
  faStar
)

export default class App extends PureComponent {
  render() {
    return (
      <Provider store={store}>
        <Fragment>
          <CFSelect selector={dispatch.user.getReviewOrderId}>
            {orderId => (orderId ? <ReviewModal orderId={orderId} /> : null)}
          </CFSelect>
          <Routes />
          <Notification />
          <SubscribeRestaurantLocation />
          <CFSelect selectorNot={dispatch.restaurant.getLoading}>
            <CFRenderless
              setInterval={dispatch.restaurant.updateActiveCategoryOrder}
              setIntervalCallOnMount
              setIntervalms={60000}
            />
          </CFSelect>
        </Fragment>
      </Provider>
    )
  }
}
