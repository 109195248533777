import React, { Component } from 'react'
import { css } from 'emotion'
import { CFLink, CFView, Space, PageHeader } from 'components'
import { hero, downloadButton } from 'images'
import { MEDIA_QUERY } from 'styles'

class Hero extends Component {
  render() {
    const { OrderNowButtonElement } = this.props
    return (
      <div className={styles.container}>
        <PageHeader />
        <img src={hero} alt="Hero" className={styles.hero} />
        <CFView className={styles.buttons}>
          {OrderNowButtonElement}
          <Space w2 />
          <div className={styles.downloadButton}>
            <CFLink href="http://onelink.to/88kv4j">
              <img
                src={downloadButton}
                alt="Download App"
                className={styles.downloadButton}
              />
            </CFLink>
          </div>
        </CFView>
      </div>
    )
  }
}
const styles = {
  container: css({
    height: '100vh',
    width: '100%',
    position: 'relative',
    [MEDIA_QUERY.MD]: {
      height: '70vh',
      backgroundAttachment: 'scroll',
    },
  }),
  hero: css({
    top: '10rem',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'bottom',
    position: 'absolute',
    height: '130vh',
    zIndex: -99,
    [MEDIA_QUERY.MD]: {
      top: '14rem',
      objectFit: 'contain',
      objectPosition: 'top',
    },
  }),
  buttons: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '120rem',
    position: 'absolute',
    margin: 'auto',
    left: '0',
    right: '0',
    bottom: '8%',
    [MEDIA_QUERY.MD]: {
      flexDirection: 'column',
      bottom: 0,
    },
  }),
  downloadButton: css({
    [MEDIA_QUERY.SM]: {
      width: '30rem',
    },
  }),
}

export default Hero
