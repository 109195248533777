import React, { Component } from 'react'
import { CFButton, CFSelect, CFModal } from 'cf-web-app/components'
import { dispatch } from 'cf-web-app/store'
import { ThemeContext } from 'cf-web-app'

export default class LocationSelect extends Component {
  state = {
    showModal: false,
  }
  componentDidMount() {
    if (!dispatch.restaurant.getSelectedLocationId()) {
      this.setState({ showModal: true })
    }
  }
  render() {
    const { showModal } = this.state
    return (
      <CFSelect selector={dispatch.restaurant.getHasMultipleLocations}>
        <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
          <CFButton onClick={() => this.setState({ showModal: true })}>
            <ThemeContext.Consumer>
              {({ Components }) => (
                <Components.LocationSelectNameView
                  selectedLocationName={
                    <CFSelect selector={dispatch.restaurant.getLocationName} />
                  }
                />
              )}
            </ThemeContext.Consumer>
          </CFButton>
        </CFSelect>
        <CFModal isOpen={showModal}>
          <ThemeContext.Consumer>
            {({ Components }) => (
              <Components.LocationSelectModalView
                LocationSelectListElement={
                  <CFSelect selector={dispatch.restaurant.getLocations}>
                    {locations =>
                      Object.values(locations).map(this._renderLocationButton)
                    }
                  </CFSelect>
                }
              />
            )}
          </ThemeContext.Consumer>
        </CFModal>
      </CFSelect>
    )
  }
  _renderLocationButton = location => {
    const { id, locationName, address } = location
    return (
      <ThemeContext.Consumer key={id}>
        {({ Components }) => (
          <CFButton onClick={() => this._handleLocationClick(id)}>
            <Components.LocationSelectButtonView
              id={id}
              name={locationName}
              address={address}
            />
          </CFButton>
        )}
      </ThemeContext.Consumer>
    )
  }
  _handleLocationClick = id => {
    dispatch.restaurant.setLocationId(id)
    this.setState({ showModal: false })
  }
}
