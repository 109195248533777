import React, { Component } from 'react'
import { css } from 'emotion'
import { foodGallery } from 'images'
import { MEDIA_QUERY } from 'styles'

class About extends Component {
  render() {
    return (
      <div className={styles.container}>
        <img className={styles.gallery} src={foodGallery} alt="Food Gallery" />
      </div>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    maxWidth: '130rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
  }),
  gallery: css({
    width: '100%',
    objectFit: 'cover',
    [MEDIA_QUERY.MD]: {
      objectFit: 'contain',
    },
  }),
}

export default About
