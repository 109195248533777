import React, { Component } from 'react'
import { CFButton, CFModal, CFSelect, PlacesInput } from 'cf-web-app/components'
import { dispatch } from 'cf-web-app/store'
import { ThemeContext } from 'cf-web-app'
import { isCoordinateInDeliveryZone } from 'cf-utils'

export default class Delivery extends Component {
  state = {
    showModal: false,
    submitting: false,
    address: '',
    latLng: {},
    editing: true,
  }
  addressInput = React.createRef()
  render() {
    return (
      <ThemeContext.Consumer>
        {({ Cart, Components }) => (
          <CFSelect
            selector={[
              dispatch.user.getOrderType,
              dispatch.user.getAddress,
              dispatch.user.getTip,
              dispatch.restaurant.getWaitTime,
              dispatch.restaurant.getDeliveryTime,
              dispatch.restaurant.getDeliveryEnabled,
              dispatch.restaurant.getDeliveryOpen,
              dispatch.restaurant.getMinOrder,
            ]}
          >
            {([
              orderType,
              address,
              tip,
              waitTime,
              deliveryTime,
              deliveryEnabled,
              deliveryOpen,
              minOrder,
            ]) => (
              <>
                <Cart.DeliveryView
                  deliveryEnabled={deliveryEnabled}
                  orderType={orderType}
                  PickupButtonElement={
                    <CFButton
                      onClick={() => dispatch.user.setOrderType('Pickup')}
                    >
                      <Cart.PickupButtonView toggled={orderType === 'Pickup'} />
                    </CFButton>
                  }
                  DeliveryButtonElement={
                    <CFButton onClick={this._handleDeliveryButtonPress}>
                      <Cart.DeliveryButtonView
                        disabled={!deliveryOpen}
                        toggled={orderType === 'Delivery'}
                      />
                    </CFButton>
                  }
                  TimeAndPlaceElement={
                    <Cart.TimeAndPlaceView
                      delivery={orderType === 'Delivery'}
                      address={address}
                      waitTime={waitTime}
                      deliveryTime={deliveryTime}
                      minOrder={minOrder}
                      EditAddressButtonElement={
                        <CFButton
                          onClick={() => this.setState({ showModal: true })}
                        >
                          <Components.EditButtonView />
                        </CFButton>
                      }
                    />
                  }
                  TipElement={
                    <Cart.TipView
                      Tip1ButtonElement={
                        <CFButton onClick={() => dispatch.user.setTip(0.1)}>
                          <Cart.Tip1ButtonView toggled={tip === 0.1} />
                        </CFButton>
                      }
                      Tip2ButtonElement={
                        <CFButton onClick={() => dispatch.user.setTip(0.15)}>
                          <Cart.Tip2ButtonView toggled={tip === 0.15} />
                        </CFButton>
                      }
                      Tip3ButtonElement={
                        <CFButton onClick={() => dispatch.user.setTip(0.2)}>
                          <Cart.Tip3ButtonView toggled={tip === 0.2} />
                        </CFButton>
                      }
                      NoTipButtonElement={
                        <CFButton onClick={() => dispatch.user.setTip(0)}>
                          <Cart.NoTipButtonView toggled={tip === 0} />
                        </CFButton>
                      }
                    />
                  }
                />
                <CFModal isOpen={this.state.showModal}>
                  <Cart.UserAddressModalView
                    AddressInputElement={
                      <PlacesInput
                        address={this.state.address}
                        onChange={this._handleAddressChange}
                        onSelect={this._handleAddressSelect}
                        coordinate={latLng => this.setState({ latLng })}
                      />
                    }
                    CancelButtonElement={
                      <CFButton onClick={this._handleCancelPress}>
                        <Components.PrimaryButtonView label="CANCEL" />
                      </CFButton>
                    }
                    SubmitButtonElement={
                      <CFButton
                        onClick={this._handleUserAddressSubmitPress}
                        disabled={this.state.editing || this.state.submitting}
                      >
                        <Components.PrimaryButtonView
                          label="SUBMIT"
                          disabled={this.state.editing || this.state.submitting}
                        />
                      </CFButton>
                    }
                  />
                </CFModal>
              </>
            )}
          </CFSelect>
        )}
      </ThemeContext.Consumer>
    )
  }
  _handleDeliveryButtonPress = e => {
    e.preventDefault()
    if (!dispatch.restaurant.getDeliveryOpen()) {
      dispatch.notification.setMessage({
        message: 'Delivery is closed!',
        level: 'error',
      })
      return
    }
    if (!dispatch.restaurant.getIsDeliveryHoursOpen()) {
      const deliveryHoursTime = dispatch.restaurant.getDeliveryHoursTime()
      if (deliveryHoursTime === 'Not Available') {
        dispatch.notification.setMessage({
          message: `Delivery is closed today!`,
          level: 'error',
        })
      } else {
        dispatch.notification.setMessage({
          message: `Delivery is open between ${deliveryHoursTime}!`,
          level: 'error',
        })
      }
      return
    }
    if (!dispatch.user.getAddress()) {
      this.setState({ showModal: true })
    }
    dispatch.user.setOrderType('Delivery')
  }
  _handleAddressChange = address => {
    this.setState({
      address,
      editing: true,
    })
  }
  _handleAddressSelect = (address, placeId) => {
    if (address) {
      this.setState({ address, editing: false })
    }
  }
  _handleCancelPress = e => {
    e.preventDefault()
    if (!dispatch.user.getAddress()) {
      dispatch.user.setOrderType('Pickup')
    }
    this.setState({ showModal: false })
  }
  _handleUserAddressSubmitPress = e => {
    e.preventDefault()
    const { latLng } = this.state
    const deliveryZone = dispatch.restaurant.getDeliveryZone()
    if (!isCoordinateInDeliveryZone(latLng, deliveryZone)) {
      dispatch.notification.setMessage({
        message: 'Sorry, your address is outside of our delivery area',
        level: 'error',
      })
      return
    }
    const address = this.state.address
      .replace(', BC', '')
      .replace(', Canada', '')
      .trim()
    this.setState({ submitting: true })
    dispatch.user
      .setAddress({ address, latLng })
      .then(() => {
        this.setState({ showModal: false })
      })
      .catch(e => {
        dispatch.notification.setMessage({ message: e.message, level: 'error' })
      })
      .finally(() => {
        this.setState({ submitting: false })
      })
  }
}
