import React, { Component } from 'react'
import { CFButton, CFInput, CFModal } from 'cf-web-app/components'
import { dispatch } from 'cf-web-app/store'
import { isEmpty } from 'lodash'
import { moment } from 'cf-utils'
import { ThemeContext } from 'cf-web-app'

export default class ReviewModal extends Component {
  componentDidMount() {
    const { orderId } = this.props
    if (orderId) {
      dispatch.user.getOrder({ orderId }).then(order => {
        if (this._isReviewOrderTime(order)) {
          const ratings = this._generateRatings(order)
          this.setState({ order, ratings, showModal: true })
        }
      })
    }
  }
  state = {
    order: {},
    ratings: {},
    showModal: false,
  }
  commentsInput = React.createRef()
  render() {
    const { showModal, order, ratings } = this.state
    const products = { ...order.products, ...order.rewards }
    return (
      <CFModal isOpen={showModal}>
        <ThemeContext.Consumer>
          {({ Components }) => (
            <Components.ReviewModalView
              ReviewItemListElement={Object.keys(ratings)
                .sort()
                .map(productId => {
                  return (
                    <Components.ReviewItemView
                      name={products[productId].name}
                      RatingButtonElement={[1, 2, 3, 4, 5].map(value => (
                        <CFButton
                          onClick={() =>
                            this._handleRatingClick(productId, value)
                          }
                        >
                          <Components.ReviewRatingButtonView
                            isChecked={value <= ratings[productId]}
                          />
                        </CFButton>
                      ))}
                    />
                  )
                })}
              CommentInputElement={
                <CFInput
                  ref={this.commentsInput}
                  multiline
                  {...Components.reviewCommentInputProps}
                />
              }
              SkipButtonElement={
                <CFButton onClick={this._handleSkipClick}>
                  <Components.ReviewSkipButtonView />
                </CFButton>
              }
              SubmitButtonElement={
                <CFButton onClick={this._handleSubmitClick}>
                  <Components.ReviewSubmitButtonView />
                </CFButton>
              }
            />
          )}
        </ThemeContext.Consumer>
      </CFModal>
    )
  }
  _handleRatingClick = (productId, rating) => {
    this.setState({
      ratings: {
        ...this.state.ratings,
        [productId]: rating,
      },
    })
  }
  _handleSkipClick = e => {
    e.preventDefault()
    const { orderId } = this.props
    const review = 'SKIPPED'
    dispatch.user.updateOrderReview({ orderId, review })
  }
  _handleSubmitClick = e => {
    e.preventDefault()
    const { orderId } = this.props
    const comments = this.commentsInput.current.value
    const ratings = this._trimRatings(this.state.ratings)
    if (isEmpty(ratings) && !comments) {
      dispatch.notification.setMessage({
        message: 'Ratings cannot be empty!',
        level: 'error',
      })
    } else {
      const review = { comments: comments || '', ratings }
      dispatch.user.updateOrderReview({ orderId, review }).then(() => {
        dispatch.notification.setMessage({
          message: 'Thank you for your review!',
          level: 'success',
        })
      })
    }
  }
  _isReviewOrderTime(order) {
    return (
      order.status === 'Done' &&
      moment(order.createdAt)
        .add(3, 'h')
        .isBefore()
    )
  }
  _generateRatings(order) {
    const rewards = Object.values(order.rewards).reduce((prev, reward) => {
      if (reward.productId) {
        prev[reward.productId] = 0
      }
      return prev
    }, {})
    const products = Object.keys(order.products).reduce((prev, productId) => {
      prev[productId] = 0
      return prev
    }, {})
    return { ...products, ...rewards }
  }
  _trimRatings = ratings => {
    return Object.keys(ratings).reduce((prev, productId) => {
      if (ratings[productId] > 0) {
        prev[productId] = ratings[productId]
      }
      return prev
    }, {})
  }
}
