import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import * as pages from 'cf-web-app/pages'
import { dispatch } from 'cf-web-app/store'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      dispatch.user.getIsLoggedIn() === true ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: `/auth${props.location.pathname}`,
          }}
        />
      )
    }
  />
)

export default class Routes extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={pages.Home} />
          <Route path="/menu" component={pages.Menu} />
          <Route path="/auth/:nextRoute" component={pages.Auth} />
          <Route path="/cart" component={pages.Cart} />
          <PrivateRoute path="/payment/:nextRoute" component={pages.Payment} />
          <PrivateRoute path="/user" component={pages.User} />
          <Route component={pages.Page404} />
        </Switch>
      </Router>
    )
  }
}
