import React, { Component } from 'react'
import { CFSelect, CFButton } from 'cf-web-app/components'
import { dispatch } from 'cf-web-app/store'
import { isEmpty } from 'lodash'
import { ThemeContext } from 'cf-web-app'

export default class ProductItem extends Component {
  state = {
    quantity: 1,
    selectedOption: '',
    showOrderBar: false,
  }
  componentDidMount() {
    const { options } = this.props
    if (!isEmpty(options)) {
      this.setState({ selectedOption: options[0] })
    }
  }
  render() {
    const { quantity, showOrderBar } = this.state
    const { name, description, price, options, id } = this.props
    const isOptionsEmpty = isEmpty(options)
    return (
      <ThemeContext.Consumer>
        {({ Menu }) => (
          <CFSelect
            selector={[
              () => dispatch.restaurant.getIsProductActive(id),
              () => dispatch.restaurant.getIsMenuProductActive(id),
            ]}
          >
            {([isActive, isMenuActive]) => (
              <Menu.ProductItemView
                nameFormatted={name.toUpperCase()}
                isSoldOut={!isActive}
                isProductDetailsOpen={showOrderBar}
                isOptionsEmpty={isOptionsEmpty}
                price={price}
                priceFormatted={price.toFixed(2)}
                CloseProductDetailsButtonElement={
                  <CFButton
                    onClick={() => this.setState({ showOrderBar: false })}
                  >
                    <Menu.CloseProductDetailsButtonView />
                  </CFButton>
                }
                OpenProductDetailsButtonElement={
                  <CFButton
                    onClick={() => this.setState({ showOrderBar: true })}
                  >
                    <Menu.OpenProductDetailsButtonView />
                  </CFButton>
                }
                description={description}
                OptionsListElement={
                  !isOptionsEmpty && showOrderBar && this._renderOptions()
                }
                SubtractQtyButtonElement={
                  <CFButton
                    disabled={quantity === 1}
                    onClick={this._handleDecrementQuantity}
                  >
                    <Menu.SubtractQtyButtonView disabled={quantity === 1} />
                  </CFButton>
                }
                IncrementQtyButtonElement={
                  <CFButton
                    disabled={quantity === 50}
                    onClick={this._handleIncrementQuantity}
                  >
                    <Menu.IncrementQtyButtonView disabled={quantity === 50} />
                  </CFButton>
                }
                quantity={quantity}
                AddToCartButtonElement={
                  <CFButton onClick={this._handleAddToCartClick}>
                    <Menu.AddToCartButtonView isMenuActive={isMenuActive} />
                  </CFButton>
                }
              />
            )}
          </CFSelect>
        )}
      </ThemeContext.Consumer>
    )
  }
  _renderOptions = () => {
    const { selectedOption } = this.state
    const { options } = this.props
    return (
      <div>
        {options.map(id => {
          const product = dispatch.restaurant.getProductDetails(id)
          return (
            <ThemeContext.Consumer key={id}>
              {({ Menu, Components }) => (
                <Menu.ProductOptionView
                  isOptionSelected={selectedOption === id}
                  optionName={product.optionName}
                  optionNameFormatted={product.optionName.toUpperCase()}
                  optionPrice={product.price}
                  optionPriceFormatted={product.price.toFixed(2)}
                  RadioButtonElement={
                    <CFButton
                      disabled={selectedOption === id}
                      onClick={() => this.setState({ selectedOption: id })}
                    >
                      <Components.RadioButtonView
                        checked={selectedOption === id}
                      />
                    </CFButton>
                  }
                />
              )}
            </ThemeContext.Consumer>
          )
        })}
      </div>
    )
  }
  _handleIncrementQuantity = () => {
    this.setState(state => ({
      quantity: state.quantity + 1,
    }))
  }
  _handleDecrementQuantity = () => {
    this.setState(state => ({
      quantity: state.quantity - 1,
    }))
  }
  _handleAddToCartClick = () => {
    const { quantity, selectedOption } = this.state
    let { id, name } = this.props
    if (selectedOption) {
      id = selectedOption
    }
    if (!dispatch.restaurant.getIsMenuProductActive(id)) {
      const { menuId } = this.props
      const { name } = dispatch.restaurant.getMenu(menuId)
      const time = dispatch.restaurant.getMenuHours(menuId)
      dispatch.notification.setMessage({
        message:
          time === 'Not Available'
            ? `Sorry, ${name} is not available at the moment`
            : `${name} is open between ${time}`,
        level: 'error',
      })
      return
    }
    dispatch.user.addCartItem({ id, qty: quantity })
    dispatch.notification.setMessage({
      message: `${name} added to the Cart!`,
      level: 'success',
    })
    this.setState({ quantity: 1, showOrderBar: false })
  }
}
