import React from 'react'
import { CFView, Text } from 'components'
import { css } from 'emotion'
import { modalLogo } from 'images'
import { MEDIA_QUERY } from 'styles'

const LocationSelectModalView = ({ LocationSelectListElement }) => (
  <div className={styles.container}>
    <CFView column w="100%">
      <CFView column w="100%" center mb="4rem">
        <img src={modalLogo} alt="On On" height="130" />
      </CFView>
      <CFView mb="2rem">
        <Text heading2 h2 center>
          Pick from the following locations:
        </Text>
      </CFView>
      {LocationSelectListElement}
    </CFView>
  </div>
)

const styles = {
  container: css({
    backgroundColor: '#232323',
    color: 'white',
    padding: '4rem',
    borderRadius: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45rem',
    [MEDIA_QUERY.SM]: {
      padding: '2rem',
      width: '100%',
    },
  }),
}

export default LocationSelectModalView
