export * from 'cf-core/src/images'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const logo =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1561054731/onon/logo.png'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562554607/onon/hero.png'
export const foodGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562554711/onon/foodGallery.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/onon/promoHeader.png'
export const promobg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/onon/promobg.png'
export const locationMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1561054687/onon/locationMap.jpg'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562554842/onon/restaurant.png'
export const orderButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/onon/orderButton.png'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/onon/downloadButton.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/onon/cflogo.png'
export const promotion1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562554739/onon/promotion1.png'
export const promotion2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562554785/onon/promotion2.png'
export const promotion3 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562554765/onon/promotion3.png'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562554675/onon/rewardbg.jpg'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/onon/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/onon/pointsMeter.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562554810/onon/rewardDefault.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/onon/rewardArrowRight.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/onon/rewardArrowLeft.png'
export const recentLogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1561054605/onon/recentLogo.jpg'
export const modalLogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1561054649/onon/modalLogo.png'
