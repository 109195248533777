import React from 'react'
import { CFView, Text } from 'components'
import { css } from 'emotion'
import { pointsMeter, rewardbg, rewardDefault } from 'images'

const RewardsLoadedView = ({
  pointsMeterWidth,
  imageUrl,
  LeftArrowButtonElement,
  RightArrowButtonElement,
  points,
  maxPoints,
  requiredPoints,
  name,
  RedeemRewardButtonElement,
}) => (
  <div className={styles.rewardContainer}>
    <div className={styles.reward}>
      {imageUrl ? (
        <img className={styles.image} src={imageUrl} alt="Reward" />
      ) : (
        <img
          className={styles.rewardDefault}
          src={rewardDefault}
          alt="Reward"
        />
      )}
    </div>
    {LeftArrowButtonElement}
    {RightArrowButtonElement}
    <div className={styles.meter}>
      <div
        className={styles.progress}
        style={{
          width: `${pointsMeterWidth * 35.6}rem`,
          transition: 'width 2s',
        }}
      >
        <img src={pointsMeter} alt="Meter" />
      </div>
    </div>
    <div className={styles.pointsText}>
      <Text h6 bold dark>
        {points} / {maxPoints}
      </Text>
    </div>
    <Text h2 bold dark className={styles.requiredPoints}>
      {requiredPoints === 0 ? 'FREE' : `${requiredPoints} POINTS`}
    </Text>
    <CFView className={styles.name}>
      <Text h2 bold dark center>
        {name ? name.toUpperCase() : ''}
      </Text>
    </CFView>
    <CFView className={styles.redeemButton}>{RedeemRewardButtonElement}</CFView>
  </div>
)

const styles = {
  rewardContainer: css({
    position: 'relative',
    height: '100%',
    width: '100%',
    background: `url(${rewardbg}) no-repeat`,
    backgroundSize: 'contain',
    display: 'flex',
    borderRadius: '1.2rem',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  reward: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  image: css({
    position: 'relative',
    top: '-1.5rem',
    width: '16.5rem',
    borderRadius: '16rem',
    boxShadow: '1px 1px 4px rgba(0,0,0,.3)',
  }),
  rewardDefault: css({
    position: 'relative',
    left: '0.2rem',
    bottom: '1.6rem',
    objectFit: 'contain',
    width: '17rem',
  }),
  requiredPoints: css({
    position: 'absolute',
    top: '14rem',
  }),
  name: css({
    width: '100%',
    position: 'absolute',
    bottom: '16rem',
    textAlign: 'center'
  }),
  redeemButton: css({
    position: 'absolute',
    bottom: '4rem',
  }),
  meter: css({
    position: 'absolute',
    top: '2.4rem',
    left: '1rem',
  }),
  progress: css({
    overflow: 'hidden',
  }),
  pointsText: css({
    position: 'absolute',
    top: '5.75rem',
    right: '1.8rem',
  }),
}

export default RewardsLoadedView
